import {ToastContainer} from 'react-toastify';
import {UseWalletProvider} from 'use-wallet';
import About from './Components/About';
import Benefit from './Components/Benefits';
import CommunityArea from './Components/CommunityArea';
import Faq from './Components/Faq';
import Footer from './Components/Footer';
import Header from './Components/Header';
import {Hero} from './Components/Hero';
import NFTArea from './Components/NFTArea';
import RoadMap from './Components/RoadMap';
import 'react-toastify/dist/ReactToastify.css';
import {NFTProvider} from './Contexts/NFTContext';

function App() {


    return (
        <UseWalletProvider
            chainId={process.env.CHAIN_ID}
            connectors={{}}
            pollBalanceInterval={2000}
            pollBlockNumberInterval={5000}
        >
            <NFTProvider>
                <Header/>
                <Hero/>
                <Benefit/>
                <About/>
                <NFTArea/>
                <RoadMap/>
                <Faq/>
                <CommunityArea/>
                <Footer/>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </NFTProvider>
        </UseWalletProvider>
    );
}

export default App;
