export default function Benefit() {
    return (
        <section className="benefit__area">
            <div className="container">
                <div className="common__title" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                    <h3 className="text-white"><span className="text--pink">Lil Floki</span> NFT Benefits</h3>
                </div>
                <div className="benefit__wrapper">
                    <div className="row">
                        <div className="col-md-4 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="single__benefit__item">
                                <div className="single__benefit__icon">
                                    <img src="assets/img/i-1.png" alt=""/>
                                </div>
                                <div className="single__benefit__content">
                                    <p>Be one of the first to own a <br/> first-generation <a href=""> Lil Floki
                                        NFT!</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="single__benefit__item">
                                <div className="single__benefit__icon">
                                    <img src="assets/img/i-2.png" alt=""/>
                                </div>
                                <div className="single__benefit__content">
                                    <p>Each <a href=""> NFT</a> is unique to you, some <br/> rarer than others</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="single__benefit__item">
                                <div className="single__benefit__icon">
                                    <img src="assets/img/i-3.png" alt=""/>
                                </div>
                                <div className="single__benefit__content">
                                    <p>Receive special perks in our Future <br/> <a href="">Lil Floki P2E game</a></p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="single__benefit__item">
                                <div className="single__benefit__icon">
                                    <img src="assets/img/i-4.png" alt=""/>
                                </div>
                                <div className="single__benefit__content">
                                    <p>NFT holders also receive a lifetime 10% discount on all advertising on
                                        <a href="">LilMoonshots.com</a> Crypto Website
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="single__benefit__item">
                                <div className="single__benefit__icon">
                                    <img src="assets/img/i-5.png" alt=""/>
                                </div>
                                <div className="single__benefit__content">
                                    <p>Every purchase of a <a href="">Lil Floki NFT</a> gives you an extra chance of
                                        winning our giveaway prizes
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-6" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="single__benefit__item">
                                <div className="single__benefit__icon">
                                    <img src="assets/img/i-6.png" alt=""/>
                                </div>
                                <div className="single__benefit__content">
                                    <p>Funds will be used to further the development of the <a href="">Lil Floki
                                        Project</a> and for marketing/giveaways
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}