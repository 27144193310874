export default function Faq() {
    return (
        <section className="faq__area" id="faq">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="faq__thumb" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <img src="assets/img/faq-thumb.png" alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="faq__wrapper" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <div className="common__title">
                                <h3 className="text-start">FAQ</h3>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingOne">
                                        <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne" aria-expanded="true"
                                                aria-controls="collapseOne">
                                            How do I purchase a Lil Floki NFT? <i className="fal fa-chevron-down"></i>
                                        </button>
                                    </h2>
                                    <div id="collapseOne" className="accordion-collapse collapse show"
                                         aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Simply connect your Binance Smart Chain (BSC) Wallet through either
                                                Metamask, Trustwallet or Coinbase. Make sure you have enough BNB in your
                                                wallet to purchase. When ready select how many NFT’s you want to mint
                                                then click Mint NFT and
                                                accept the transaction. The NFT will now show-up in your Wallet and be
                                                connected to you on the blockchain!</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="accordion-item">
                                    <h2 className="accordion-header" id="headingTwo">
                                        <button className="accordion-button collapsed" type="button"
                                                data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                                                aria-expanded="false" aria-controls="collapseTwo">
                                            How do I view my NFT's? <i className="fal fa-chevron-down"></i>
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" className="accordion-collapse collapse"
                                         aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div className="accordion-body">
                                            <p>Simply add our NFT Contract
                                                Address <strong>0xc64930c5fa5c4ed5842307427c6eece09e86c597</strong> to
                                                your Trustwallet or Metamask. NFT's will also give you exclusive perks
                                                in our future Lil Floki P2E game. In the future you will be able to view
                                                your NFTs on our website as well. </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}