import React, {createContext, useEffect, useState} from 'react';
import {useWallet} from "use-wallet";
import {getRealContract, waitForTransaction} from "../Web3Utils/generateUtils";
import {NFTContractAddress, SwitchAndAddRequests} from "../Web3Utils/configs";
import {NftAbi} from "../Web3Utils/Abi/nftAbi";
import {toast} from 'react-toastify';
import {getEveryMintCost, mintLilNFT} from '../Web3Utils/LilNFTUtils';
import Web3 from "web3";


const NFTContext = createContext({
    amountPerMint: null,
    switchNetwork: null,
    wrongNet: false,
    connected: false,
    mint: null,
    balance: 0
});

export const NFTProvider = ({pid, children}) => {
    const wallet = useWallet();

    // context 
    const [connected, setConnected] = useState(false);
    const [amountPerMint, setAmountPerMint] = useState(0);
    const [wrongNet, setWrongNet] = useState(false);
    const [balance, setBalance] = useState('0');

    // local
    const [lastToast, setLastToast] = useState(0);

    useEffect(() => {
        if (wallet.status === 'connected' && wallet.chainId != process.env.REACT_APP_CHAIN_ID) {
            if (lastToast === 0 || performance.now() - lastToast > 5000) {
                toast.error(`Unsupported network only available on Binance Smart Chain`, {
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: false,
                });
                setLastToast(performance.now());
            }
            setWrongNet(true);
        } else {
            setWrongNet(false);
        }
        setConnected(wallet.status === 'connected' && wallet.chainId == process.env.REACT_APP_CHAIN_ID);
    }, [wallet.chainId, wallet.status]);

    useEffect(() => {
        initEveryMintCost();
        getBalance();
    }, [connected]);

    const getBalance = async () => {
        if (!connected) return;
        const balance = await new Web3(wallet.ethereum).eth.getBalance(wallet.account);
        setBalance(balance);
    }

    const initEveryMintCost = async () => {
        if (!connected) return;
        const realContract = await getRealContract(
            NFTContractAddress,
            wallet.ethereum,
            NftAbi
        );
        const cost = await getEveryMintCost(realContract);
        setAmountPerMint(cost);
    }

    const switchNetwork = async () => {
        try {
            // try and switch network
            await wallet.ethereum.request(SwitchAndAddRequests.switch);
        } catch (error) {
            // This error code indicates that the chain has not been added to MetaMask
            // if it is not, then lets add it into the user MetaMask
            if (error.code === 4902) {
                try {
                    await wallet.ethereum.request(SwitchAndAddRequests.add);
                } catch (addError) {
                    //console.error(addError);
                }
            }
            toast.error(error.message, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const mint = async (amount) => {
        const realContract = await getRealContract(
            NFTContractAddress,
            wallet.ethereum,
            NftAbi
        );
        const tx = mintLilNFT(realContract, amount, wallet);
        return handleTransactionPromise(
            {
                transactionPromise: tx,
                successMessage: "Success! You are a proud owner of a lilFloki",
                toast
            }
        )
    }

    return (
        <NFTContext.Provider
            value={{
                connected,
                wrongNet,
                amountPerMint,
                switchNetwork: switchNetwork,
                mint,
                balance
            }}
        >
            {children}
        </NFTContext.Provider>
    );
}


export const NFTConsumer = NFTContext.Consumer;

export default NFTContext;

const handleTransactionPromise = async ({transactionPromise, successMessage, toast}) => {
    let tx;
    try {
        tx = await transactionPromise;
        const receipt = await waitForTransaction(tx);

        if (receipt.status) {
            // transaction mined and did not revert
            toast.success(successMessage);
        } else {
            // transaction mined and did revert
            toast.error(
                "Transaction Reverted 😢",
            )
        }
    } catch (error) {
        toast.error(error.message);
    }
}