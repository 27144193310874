import Web3 from "web3";

const {BN} = Web3.utils;
export const getEveryMintCost = async (realContract) => {
    return await realContract.methods.everyMintCost().call();
}

export const mintLilNFT = async (realContract, amount, wallet) => {
    const data = realContract.methods.mint(new BN(amount)).encodeABI();
    const cost = await getEveryMintCost(realContract);
    const totalCost = new BN(amount).mul(new BN(cost));

    const transactionParams = {
        value: totalCost.toString('hex'),
        nonce: '0x00',
        to: realContract.options.address,
        from: wallet.account,
        data: data,
        chainId: wallet.chainId
    }

    return wallet.ethereum.request({
        method: 'eth_sendTransaction',
        params: [transactionParams],
    })
}