export default function RoadMap() {
    return (
        <section className="roadmap__area overflow-hidden" id="roadmap">
            <div className="container">
                <div className="common__title" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                    <h3 className="text-white">Roadmap</h3>
                </div>
                <div className="roadmap__wrapper">
                    <div className="single__roadmap__item" data-aos="fade-up" data-aos-delay="50"
                         data-aos-duration="1500">
                        <div className="single__roadmap__item__num">
                            P1
                        </div>
                        <div className="single__roadmap__item__content">
                            <h3>25% NFT’s Minted</h3>
                            <p>- 10K USD Giveaway to one lucky NFT holder <br/> + 10K USD Donation to Dog
                                Shelter <br/> (Community Vote) (20k USD total)</p>
                        </div>
                        <div className="roadmap__item__arrow">
                            <img src="assets/img/roadmap-arrow.png" alt=""/>
                        </div>
                    </div>
                    <div className="single__roadmap__item odd" data-aos="fade-up" data-aos-delay="50"
                         data-aos-duration="1500">
                        <div className="single__roadmap__item__num">
                            P2
                        </div>
                        <div className="single__roadmap__item__content">
                            <h3> 50% NFT’s Minted</h3>
                            <p>- 20K USD Giveaway to one lucky NFT holder <br/> + 20K USD Donation to Dog
                                Shelter <br/> (Community Vote) (40k USD total)</p>
                        </div>
                        <div className="roadmap__item__arrow">
                            <img src="assets/img/roadmap-arrow.png" alt=""/>
                        </div>
                    </div>
                    <div className="single__roadmap__item" data-aos="fade-up" data-aos-delay="50"
                         data-aos-duration="1500">
                        <div className="single__roadmap__item__num">
                            P3
                        </div>
                        <div className="single__roadmap__item__content">
                            <h3>75% NFT’s Minted</h3>
                            <p>- 5K USD Giveaway to 10 Lucky <br/> NFT Holders (50K USD Total)</p>
                        </div>
                        <div className="roadmap__item__arrow">
                            <img src="assets/img/roadmap-arrow.png" alt=""/>
                        </div>
                    </div>
                    <div className="single__roadmap__item odd" data-aos="fade-up" data-aos-delay="50"
                         data-aos-duration="1500">
                        <div className="single__roadmap__item__num">
                            P4
                        </div>
                        <div className="single__roadmap__item__content">
                            <h3> 100% NFT’s Minted</h3>
                            <p> - 50K USD Donation to Dog Shelters <br/> + 50K USD Giveaway to 1 Lucky <br/> NFT Holder
                            </p>
                        </div>
                        <div className="roadmap__item__arrow">
                            <img src="assets/img/roadmap-arrow.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="roadmap__character" data-aos="fade-left" data-aos-delay="50" data-aos-duration="1500">
                <img src="assets/img/roadmap-character.png" alt=""/>
            </div>
        </section>
    )
}