import {useWallet} from "use-wallet";
import useNFT from "../../Hooks/useNFT";

export default function Header() {
    const wallet = useWallet();

    const {switchNetwork, wrongNet} = useNFT();

    function onConnect() {
        if (wallet.status !== 'connected')
            wallet.connect();
        else if (wallet.status === 'connected' && wrongNet)
            switchNetwork();
    }


    return (
        <header className="header__area">
            <div className="container d-flex align-items-center justify-content-between">
                <div className="logo__main">
                    <a href=""><img src="assets/img/logo-main.png" alt=""/></a>
                </div>
                <div className="header__right d-flex align-items-center justify-content-end">
                    <div className="header__menu">
                        <ul>
                            <li><a href="#roadmap">NFT Roadmap</a></li>
                            <li><a href="#faq">FAQ</a></li>
                            <li className="connect--wallet d-none header__btn" style={{width: "100%",textAlign: 'center'}}>
                                <button onClick={onConnect} style={{margin: "auto"}}>
                                    {wallet.status === 'connected' && wallet.chainId == process.env.REACT_APP_CHAIN_ID && `${wallet.account.substring(0, 6) + '...' + wallet.account.substring(wallet.account.length - 4, wallet.account.length)}`}
                                    {wallet.status !== 'connected' && `Connect Wallet`}
                                    {wallet.status === 'connected' && wrongNet && `Switch to BSC`}
                                </button>
                            </li>
                        </ul>
                    </div>
                    <div className="header__btn">
                        <button onClick={onConnect}>
                            {wallet.status === 'connected' && wallet.chainId == process.env.REACT_APP_CHAIN_ID && `${wallet.account.substring(0, 6) + '...' + wallet.account.substring(wallet.account.length - 4, wallet.account.length)}`}
                            {wallet.status !== 'connected' && `Connect Wallet`}
                            {wallet.status === 'connected' && wrongNet && `Switch to BSC`}
                        </button>
                    </div>
                    <div className="menu-open">
                        <i className="far fa-bars"></i>
                    </div>
                </div>
            </div>
        </header>
    )
}