export default function CommunityArea(){
    return(
        <section className="community__area">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-7 col-md-7">
                    <div className="community__wrapper" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                        <h3>Join our community</h3>
                        <div className="social">
                            <a href="https://twitter.com/thelilfloki"><img src="assets/img/twitter.png" alt=""/></a>
                            <a href="https://reddit.com/r/lilfloki"><img src="assets/img/reddit.png" alt=""/></a>
                            <a href="https://t.me/thelilfloki"><img src="assets/img/telegram.png" alt=""/></a>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 col-md-5">
                    <div className="community__thumb">
                        <img src="assets/img/character2.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </section>
    )
}