export default function NFTArea() {
    return (
        <section className="nft__area">
            <div className="container">
                <div className="common__title" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                    <h3>NFT's</h3>
                    <p>10,000 Unique NFT’s will be available to Mint upon launch using the Binance Smart Chain Network.
                        The price to mint will be 0.2 BNB per NFT on launch. </p>
                </div>
            </div>
            <div className="nft__container">
                <div className="container">
                    <div className="nft__slider__wrapper" data-aos="fade-up" data-aos-delay="50"
                         data-aos-duration="1500">
                        <div className="swiper mySwiper">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide"><img src="assets/img/nft-1.png" alt=""/></div>
                                <div className="swiper-slide"><img src="assets/img/nft-2.png" alt=""/></div>
                                <div className="swiper-slide"><img src="assets/img/nft-3.png" alt=""/></div>
                                <div className="swiper-slide"><img src="assets/img/nft-4.png" alt=""/></div>
                            </div>
                            <div className="swiper__nav">
                                <div className="swiper-button-prev"><img src="assets/img/ar.png" alt=""/></div>
                                <div className="swiper-button-next"><img src="assets/img/ar.png" alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="mint__btn" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                    <a href="#" className="common--btn">Mint NFT </a>
                </div>
            </div>
        </section>
    )
}