import {useEffect, useState} from "react";
import {useWallet} from "use-wallet";
import useNFT from "../../Hooks/useNFT";
import {formatLongNumber} from "../../utils/NumberUtils";

export function Hero() {
    const wallet = useWallet();

    const [amountToMint, setAmountToMint] = useState(1);
    const [busy, setBusy] = useState('');
    const {connected, wrongNet, switchNetwork, mint, amountPerMint, balance} = useNFT();
    const [error, setError] = useState('');

    useEffect(() => {
        const total = Number(amountToMint) * Number(amountPerMint);
        if (total > balance)
            setError('Insufficient Balance')
        else
            setError('')
    }, [amountToMint, balance,amountPerMint])

    const mintClick = async (event) => {
        event.preventDefault();
        if (connected) {
            setBusy('Minting Nft');
            const tx = await mint(amountToMint);
            setBusy('');
        } else if (wrongNet)
            switchNetwork()
        else
            wallet.connect();
    }

    return (
        <section className="hero__area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="hero__content" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <h3>Step 1:</h3>
                            <h2>Connect your Wallet</h2>
                            <h3>Step 2:</h3>
                            <h2>Select how many NFT's you want to Mint then click the Mint button.</h2>
                            <div className="hero__btn">
                                <img src="assets/img/long-arrow.png" alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="hero__right__wrapper position-relative" style={{zIndex: 1}} data-aos="fade-up"
                             data-aos-delay="50" data-aos-duration="1500">
                            <div className="hero__card__img">
                                <img src="assets/img/card-img.png" alt=""/>
                            </div>
                            <div className="hero__card__item">
                                <div className="hero__card__thumb">
                                    <img src="assets/img/hero-thumb.png" alt=""/>
                                </div>
                                <div className="hero__card__body">
                                    <div className="hero__card__form__title">
                                        <h4>Lil Floki NFT's</h4>
                                        <h5><span className="text--pink">On Sale:</span> Now</h5>
                                    </div>
                                    <div className="hero__card__form">
                                        <form action="" onSubmit={mintClick}>
                                            <div className="form__input">
                                                <div className="nice-number">
                                                    <button disabled={!!busy || !connected} type="button"
                                                            onClick={() => {
                                                                if (amountToMint > 1) setAmountToMint(amountToMint - 1)
                                                            }}>-
                                                    </button>
                                                    <input readOnly type="number" value={amountToMint}
                                                           onChange={event => setAmountToMint(event.target.value)}
                                                           data-nice-number-initialized="true" style={{width: '2ch'}}/>
                                                    <button disabled={!!busy || !connected} type="button"
                                                            onClick={() => setAmountToMint(amountToMint + 1)}>+
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="form__submit">
                                                <button
                                                    type="submit"
                                                    disabled={!!busy || !!error}
                                                >
                                                    {
                                                        !error && !busy && wallet.status === 'connected' && wallet.chainId == process.env.REACT_APP_CHAIN_ID &&
                                                        `Mint NFT \n (${formatLongNumber((amountToMint * amountPerMint) / 10 ** 18, 4)}\u00a0BNB)`
                                                    }
                                                    {!error && !busy && wallet.status !== 'connected' && `Connect Wallet`}
                                                    {!error && !busy && wallet.status === 'connected' && wallet.chainId != process.env.REACT_APP_CHAIN_ID && `Switch to BSC`}
                                                    {
                                                        !error && busy &&
                                                        <>
                                                            <span className="spinner-border spinner-border-sm"
                                                                  role="status" aria-hidden="true"
                                                                  style={{marginRight: 5}}/>
                                                            {busy}
                                                        </>
                                                    }
                                                    {
                                                        !!error && `${error}`
                                                    }
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                    <p>Available for a limited time only!</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}