export default function Footer() {
    return (
        <footer className="footer__area">
            <div className="container">
                <div className="footer__top">
                    <div className="footer__logo">
                        <a href=""><img src="assets/img/logo-footer.png" alt=""/></a>
                    </div>
                </div>
                <p>Copyright {new Date().getFullYear()} <a href="https://lilfloki.com/">Lil Floki</a>. All rights
                    reserved.</p>
            </div>
        </footer>
    )
}