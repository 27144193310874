export default function About() {
    return (
        <section className="about__area">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-5 col-md-6">
                        <div className="about__content" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <h3>About <span className="text--pink">Lil Floki</span></h3>
                            <p>The Lil Floki project was launched on 25th September 2021 and experienced tremendous
                                growth. By minting a Lil Floki NFT
                                you are supporting a long-term project with a vision for helping NZ Dog Shelters.
                                <br/><br/>By holding our NFTs you will also gain exclusive features in our future P2E
                                Lil Floki game. Every NFT you purchase will also give you more chances to win our big
                                cash prize draws.</p>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6">
                        <div className="about__thumb" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500">
                            <img className="fr-desktop" src="assets/img/character-1.png" alt=""/>
                            <img className="fr-mobile d-none" src="assets/img/character-11.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}