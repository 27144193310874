const nftDeployments = {
    '42': '0x75b003daf2f390fcf39112077953d823ccefc8ff',
    '56': '0xc64930C5Fa5C4ed5842307427c6EeCe09E86c597'
}


const addSwitchNetworkRequests = {
    '42': {
        add: {
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x2a',
                    chainName: 'Kovan Test Net',
                    nativeCurrency: {
                        name: 'Kovan Ethereum',
                        symbol: 'KETH', // 2-6 characters long
                        decimals: 18
                    },
                    blockExplorerUrls: ['https://kovan.etherscan.io/'],
                    rpcUrls: ['https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'],
                },
            ],
        },
        switch: {
            method: 'wallet_switchEthereumChain',
            params: [{chainId: '0x2a'}],
        }
    },
    '56': {
        add: {
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x38',
                    chainName: 'Binance Smart Chain',
                    nativeCurrency: {
                        name: 'Binance Coin',
                        symbol: 'BNB', // 2-6 characters long
                        decimals: 18
                    },
                    blockExplorerUrls: ['https://bscscan.com/'],
                    rpcUrls: ['https://bsc-dataseed1.binance.org/'],
                },
            ],
        },
        switch: {
            method: 'wallet_switchEthereumChain',
            params: [{chainId: '0x38'}],
        }
    }
}

const rpcs = {
    '42': 'https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    '56': 'https://bsc-dataseed1.binance.org'
}

export const SwitchAndAddRequests = addSwitchNetworkRequests[process.env.REACT_APP_CHAIN_ID];

export const NFTContractAddress = nftDeployments[process.env.REACT_APP_CHAIN_ID];

export const rpcUrl = rpcs[process.env.REACT_APP_CHAIN_ID];
